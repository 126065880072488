import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Artist from '../components/artist'

export const query = graphql`
  query ArtistQuery($id: String!) {
    artist: sanityArtist(id: { eq: $id }) {
      _id
      id
      first_name
      intro
      sub_intro
      surname
      _rawBiographyBlocks(resolveReferences: {maxDepth: 10})
      biography_blocks {
        _rawChildren
        _type
        list
        style
        children {
          _key
          _type
          text
          marks
        }
      }
      overlay_colour
      social_facebook
      social_instagram
      social_spotify
      social_tiktok
      social_twitter
      social_youtube
      social_website
      social_website_label
      showreel
      links {
        url
        title
      }
      credits {
        title
        startdate
        role
        info
        enddate
        hide_date
        show_end_date
        category
        highlight
      }
      credit_order {
        title
      }
      contact_1_show_details
      contact_1_role
      contact_1_name {
        title
        phone
        email
      }
      contact_2_role
      contact_2_name {
        title
        phone
        email
      }
      contact_3_role
      contact_3_name {
        title
        phone
        email
      }
      contact_4_role
      contact_4_name {
        title
        phone
        email
      }
      external_contact_role
      external_contact_name {
        title
        phone
        email
      }
      external_contact_role_2
      external_contact_name_2 {
        title
        phone
        email
      }
      external_contact_role_3
      external_contact_name_3 {
        title
        phone
        email
      }
      external_contact_role_4
      external_contact_name_4 {
        title
        phone
        email
      }
        artist_podcasts {
        image {
          asset {
            url
          }
        }
        podcast_acast
        podcast_spotify
        podcast_amazon
        podcast_apple
        podcast_bbc
        podcast_google
        podcast_pocketcasts
        _rawRelatedArtists(resolveReferences: {maxDepth: 10})
        title
        guest_appearance
        description
      }
      artist_video {
        title
        youtube_vimeo
      }
      sounds {
        title
        links {
          title
          url
          _key
        }
      }
      artist_audio {
        soundcloud
      }
      headshot_zoom
      headshot {
        ...ImageWithPreview
        asset {
          url
          publicUrl
          filename
        }
       }
       headshot_thumbnail {
        ...ImageWithPreview
       }
    }
  }
`


const ArtistTemplate = props => {

  const { data, errors } = props
  const gallery = props.pageContext.gallery;
  const artist = data && data.artist;

  return (
    <div>
      <Helmet
        bodyAttributes={{
          class: 'artist-page'
        }}
      />
      {artist && <Artist gallery={gallery} {...artist} />}
    </div>
  )
}

export default ArtistTemplate
