import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { slugify } from '../../lib/utils'
import IconFacebook from '../../images/facebook_pink.svg'
import IconInstagram from '../../images/instagram_pink.svg'
import IconTwitter from '../../images/twitter_pink.svg'
import IconSpotify from '../../images/spotify.svg'
import IconYoutube from '../../images/youtube-pink.svg'
import IconTikTok from '../../images/tiktok.svg'

const Container = styled.div`
  padding-top: 0.5rem;
  min-width: 240px;
  h3 {
    color: var(--pink);
    font-weight: 600;
    font-size: 28px;
  }
  @media (max-width: 880px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    &.credits, &.media, &.news, &.podcasts {
      display: none;
    }
  }
`

const Section = styled.section`
  border-bottom: 1px solid #E0E0E0;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  &:last-of-type {
    border-bottom: none;
  }
  @media(max-width: 880px) {
    border-bottom: 1px solid #E0E0E0 !important;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
  dl {
    padding-top: 1rem;
    p {
      padding-bottom: 1rem;
    }
  }
  h4 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    padding-bottom: 1rem;
    color: var(--darkgrey);
  }
  dd {
    font-family: Catamaran,sans-serif;
    font-weight: 300;
    a {
      font-family: Catamaran, sans-serif;
      text-decoration: none;
      color: var(--pink);
    }
  }
  dt {
    font-family: Catamaran,sans-serif;
    opacity: 0.5;
  }
  p {
    font-family: Catamaran,sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
  }
  a {
    color: var(--text);
    font-weight: 300;
  }
`

const Socials = styled.div`
  @media(max-width: 880px) {
    padding-left: 1rem;
    padding-top: 0.5rem;
  }
  a {
    font-family: Catamaran,sans-serif;
    text-decoration: none;
    color: var(--text);
    &.pink {
      text-decoration: underline;
      color: var(--pink);
    }
  }
`

const Flex = styled.div`
  display: flex;
  margin-bottom: 0.25rem;
  img {
    height: 25px;
    margin-right: 0.75rem;
    &:hover {
      filter: brightness(0%);
    }
  }
`

const ExtraLinks = styled.ul`
  list-style: none;
  padding-left: 0;
  margin-left: 0;
  a {
    color: var(--pink);
    text-decoration: underline;
  }
`

const ArtistSidebar = (props) => {

  const { section } = props;

  const {
    links,
    contact_1_show_details,
    contact_1_role,
    contact_1_name,
    contact_2_role,
    contact_2_name,
    contact_3_role,
    contact_3_name,
    contact_4_role,
    contact_4_name,
    contact_5_name,
    contact_5_role,
    contact_6_name,
    contact_6_role,
    contact_7_name,
    contact_7_role,
    contact_8_name,
    contact_8_role,
    contact_9_name,
    contact_9_role,
    contact_10_name,
    contact_10_role,
    external_contact_role,
    external_contact_name,
    external_contact_role_2,
    external_contact_name_2,
    external_contact_role_3,
    external_contact_name_3,
    external_contact_role_4,
    external_contact_name_4,
    social_facebook,
    social_instagram,
    social_spotify,
    social_tiktok,
    social_youtube,
    social_twitter,
    social_website_label,
    social_website } = props;

  const url_facebook = 'https://facebook.com/' + social_facebook;
  const url_instagram = 'https://instagram.com/' + social_instagram;
  const url_spotify = social_spotify ? 'https://open.spotify.com/artist/' + social_spotify : false;
  const url_twitter = social_twitter ? 'https://twitter.com/' + social_twitter.replace('@', '') : false;
  const url_tiktok = social_tiktok ? 'https://www.tiktok.com/@' + social_tiktok.replace('@', '') : false;
  const url_youtube = social_youtube ? 'https://www.youtube.com/' + social_youtube.replace('https://www.youtube.com', '') : false;

  let website_label;

  if (social_website) {
    website_label = social_website.split('://')[1].replace('/', '');
  }

  if (social_website_label) {
    website_label = social_website_label
  }

  return (
    <Container className={section}>
      <Socials>
        <Flex>

          {social_facebook &&
            <a rel="noreferrer noopener" href={url_facebook}><img src={IconFacebook} alt="Facebook" /></a>
          }

          {social_instagram &&
            <a rel="noreferrer noopener" href={url_instagram}><img src={IconInstagram} alt="Instagram" /></a>
          }

          {social_twitter &&
            <a rel="noreferrer noopener" href={url_twitter}><img src={IconTwitter} alt="Twitter" /></a>
          }

          {social_spotify &&
            <a rel="noreferrer noopener" href={url_spotify}><img src={IconSpotify} alt="Spotify" /></a>
          }

          {social_tiktok &&
            <a rel="noreferrer noopener" href={url_tiktok}><img src={IconTikTok} alt="Tiktok" /></a>
          }

          {social_youtube &&
            <a rel="noreferrer noopener" href={url_youtube}><img src={IconYoutube} alt="Youtube" /></a>
          }

        </Flex>

        {social_website &&
          <a className="pink" href={social_website}>{website_label}</a>
        }

        {links &&
          <ExtraLinks>
            {links.map((l, index) => {
              return (
                <li key={index}><a href={l.url}>{l.title}</a></li>
              )
            })}
          </ExtraLinks>
        }

      </Socials>


      <Section>
        <h3>Contacts</h3>
        <dl>

          {contact_1_role &&
            <>
              <dt>{contact_1_role}:</dt>
              <dd><Link to={"/people/" + slugify(contact_1_name.title)}>{contact_1_name.title}</Link></dd>
              {contact_1_show_details &&
                <p><a href={`mailto:${contact_1_name.email}`}>{contact_1_name.email}</a><br />{contact_1_name.phone}</p>
              }
              {!contact_1_show_details &&
                <p></p>
              }
            </>
          }

          {contact_2_role && contact_2_name?.title &&
            <>
              <dt>{contact_2_role}:</dt>
              <dd><Link to={"/people/" + slugify(contact_2_name.title)}>{contact_2_name.title}</Link></dd>
              <p><a href={`mailto:${contact_2_name.email}`}>{contact_2_name.email}</a><br />{contact_2_name.phone}</p>
            </>
          }

          {contact_3_role && contact_3_name?.title &&
            <>
              <dt>{contact_3_role}:</dt>
              <dd><Link to={"/people/" + slugify(contact_3_name.title)}>{contact_3_name.title}</Link></dd>
              <p><a href={`mailto:${contact_3_name.email}`}>{contact_3_name.email}</a><br />{contact_3_name.phone}</p>
            </>
          }

          {contact_4_role && contact_4_name?.title &&
            <>
              <dt>{contact_4_role}:</dt>
              <dd><Link to={"/people/" + slugify(contact_4_name.title)}>{contact_4_name.title}</Link></dd>
              <p><a href={`mailto:${contact_4_name.email}`}>{contact_4_name.email}</a><br />{contact_4_name.phone}</p>
            </>
          }

          {contact_5_role && contact_5_name?.title &&
            <>
              <dt>{contact_5_role}:</dt>
              <dd><Link to={"/people/" + slugify(contact_5_name.title)}>{contact_5_name.title}</Link></dd>
              <p><a href={`mailto:${contact_5_name.email}`}>{contact_5_name.email}</a><br />{contact_5_name.phone}</p>
            </>
          }

          {contact_6_role && contact_6_name?.title &&
            <>
              <dt>{contact_6_role}:</dt>
              <dd><Link to={"/people/" + slugify(contact_6_name.title)}>{contact_6_name.title}</Link></dd>
              <p><a href={`mailto:${contact_6_name.email}`}>{contact_6_name.email}</a><br />{contact_6_name.phone}</p>
            </>
          }

          {contact_7_role && contact_7_name?.title &&
            <>
              <dt>{contact_7_role}:</dt>
              <dd><Link to={"/people/" + slugify(contact_7_name.title)}>{contact_7_name.title}</Link></dd>
              <p><a href={`mailto:${contact_7_name.email}`}>{contact_7_name.email}</a><br />{contact_7_name.phone}</p>
            </>
          }

          {contact_8_role && contact_8_name?.title &&
            <>
              <dt>{contact_8_role}:</dt>
              <dd><Link to={"/people/" + slugify(contact_8_name.title)}>{contact_8_name.title}</Link></dd>
              <p><a href={`mailto:${contact_8_name.email}`}>{contact_8_name.email}</a><br />{contact_8_name.phone}</p>
            </>
          }

          {contact_9_role && contact_9_name?.title &&
            <>
              <dt>{contact_9_role}:</dt>
              <dd><Link to={"/people/" + slugify(contact_9_name.title)}>{contact_9_name.title}</Link></dd>
              <p><a href={`mailto:${contact_9_name.email}`}>{contact_9_name.email}</a><br />{contact_9_name.phone}</p>
            </>
          }

          {contact_10_role && contact_10_name?.title &&
            <>
              <dt>{contact_10_role}:</dt>
              <dd><Link to={"/people/" + slugify(contact_10_name.title)}>{contact_10_name.title}</Link></dd>
              <p><a href={`mailto:${contact_10_name.email}`}>{contact_10_name.email}</a><br />{contact_10_name.phone}</p>
            </>
          }

        </dl>
      </Section>

      {external_contact_role &&
        <Section>
          <h4>{external_contact_role}</h4>
          <p>{external_contact_name.title}<br />
            <a href={`mailto:${external_contact_name.email}`}>{external_contact_name.email}</a><br />{external_contact_name.phone}</p>
        </Section>
      }

      {external_contact_role_2 &&
        <Section>
          <h4>{external_contact_role_2}</h4>
          <p>{external_contact_name_2.title}<br />
            <a href={`mailto:${external_contact_name_2.email}`}>{external_contact_name_2.email}</a><br />{external_contact_name_2.phone}</p>
        </Section>
      }

      {external_contact_role_3 &&
        <Section>
          <h4>{external_contact_role_3}</h4>
          <p>{external_contact_name_3.title}<br />
            <a href={`mailto:${external_contact_name_3.email}`}>{external_contact_name_3.email}</a><br />{external_contact_name_3.phone}</p>
        </Section>
      }

      {external_contact_role_4 &&
        <Section>
          <h4>{external_contact_role_4}</h4>
          <p>{external_contact_name_4.title}<br />
            <a href={`mailto:${external_contact_name_4.email}`}>{external_contact_name_4.email}</a><br />{external_contact_name_4.phone}</p>
        </Section>
      }

    </Container>
  )
}

export default ArtistSidebar
