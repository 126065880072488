import React from 'react'
import styled from 'styled-components'
import ReactPlayer from 'react-player'

const Embed = styled.div`
  margin-top: 2rem;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
`

const EmbedResponsive = styled.div`
  iframe, object, embed {
    overflow-y: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const SoundContainer = styled.div`
  margin-top: 2rem;
  width: 100%;
`

const ArtistSoundclouds = ({ soundclouds }) => {

  return (
    soundclouds.map((s, index) => {

      if(s.soundcloud.includes('soundcloud.com/tracks')) {

      const track = s.soundcloud.split('soundcloud.com')[1];

        return (
          <SoundContainer>
            <iframe width="100%"
              height="166"
              frameborder="no"
              allow="autoplay"
              src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com${track}&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true`}></iframe>
          </SoundContainer >
        )

    } else if(s.soundcloud.includes('bbc')){
      return (
        <>
        <Embed>
          <EmbedResponsive>
            <iframe src="https://www.bbc.co.uk/sounds/play/m001v2wx" frameBorder="0" allowFullScreen></iframe>
          </EmbedResponsive>
        </Embed>
        </>
      )
    } else {
      return (
        <SoundContainer>
          <iframe 
              width="100%"
              height="300"
              scrolling="no"
              frameBorder="no"
              allow="autoplay" 
              src={`https://w.soundcloud.com/player/?url=${s.soundcloud}&color=%23ee2880&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true`}>
          </iframe>
        </SoundContainer>
      )
    }}))
    
}

export default ArtistSoundclouds
