import React from 'react'
import SanityImage from 'gatsby-plugin-sanity-image'
import styled from 'styled-components'

const ImageContainer = styled.div`
  width: ${props => props.width ? props.width : '100%'};
  height: ${props => props.height ? props.height : '100%'};
  overflow: hidden;
`

const PBJ_Image = (props) => {

  const { alt, style, image, imagewidth, width, height } = props;

  const w = width ? width + 'px' : false;
  const h = height ? height + 'px' : false;

  if (image?.asset) {
    return (
      <ImageContainer width={w} height={h}>
        <SanityImage
          {...image}
          lazy
          width={imagewidth}
          style={style}
          alt={alt}
        />
      </ImageContainer>
    )
  }

}

export default PBJ_Image

