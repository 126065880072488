import React from 'react'
import Balancer from 'react-wrap-balancer'
import styled from 'styled-components'
import Image from 'gatsby-plugin-sanity-image'
import isDarkColor from 'is-dark-color'
import { blockContentToPlainText } from 'react-portable-text'
import PBJ_Image from '../pbj_image'


const Container = styled.div`
  max-width: calc(1400px - 4rem);
  margin-left: auto;
  margin-right: auto;
  min-height: 580px;
  position: relative;
  @media (max-width: 1024px) {
    min-height: 690px;
  }
  @media (max-width: 880px) {
    max-width: 100%;
    min-height: 0;
  }
`

const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: ${props => props.image_zoom};
  bottom: 0;
  z-index: 1;
  @media (max-width: 880px) {
    position: relative;
    left: 0;
  }
`

const Inner = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${props => props.text_colour};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 5rem;
  background: linear-gradient(270deg, rgba(249,250,251,0) ${props => props.image_zoom}, ${props => props.overlay_colour} ${props => props.inner_zoom});
  @media (max-width: 1024px) {
    padding: 3rem;
  }
  @media (max-width: 880px) {
    background: ${props => props.overlay_colour} !important;
    position: relative;
    background: #fff;
    padding: 1rem 1rem 2rem 1rem;
  }
  h1 {
    color: ${props => props.text_colour};
    line-height: 78px;
    max-width: 530px;
    @media (max-width: 880px) {
      font-size: 50px;
      line-height: normal;
    }
  }
  h2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
    color: ${props => props.text_colour};
    font-size: 26px;
    line-height: 30px;
    max-width: 38%;
    span {
      font-family: Catamaran, sans-serif;
    }
    @media (max-width: 880px) {
      max-width: 100%;
      font-size: 25px;
      line-height: 30px;
    }
   }
  p {
    color: ${props => props.text_colour};
    max-width: 38%;
    margin-bottom: 1rem;
    &:last-of-type {
      margin-bottom: 0;
    }
    @media (max-width: 880px) {
      max-width: 100%;
      span {
        max-width: 100% !important;
      }
    }
  }
  a {
    color: ${props => props.text_colour};
    text-decoration: none;
    border-bottom: 0.5px solid rgba(255,255,255,0.5);
  }
`

const ArtistIntro = (props) => {

  const { first_name, surname, biography_blocks, headshot, headshot_zoom, overlay_colour, intro, sub_intro, setSection } = props;

  const biography = blockContentToPlainText(biography_blocks);

  const first_sentence = intro ? intro : biography.split('.')[0];

  const remaining_sentences = sub_intro ? sub_intro : biography.split('.')[1];

  const colour = overlay_colour && overlay_colour.length === 7 ? overlay_colour : '#003257';

  const dark = isDarkColor(colour);

  const textColour = dark ? '#fff' : '#000';

  const readMore = (e) => {
    e.preventDefault();
    setSection('about');
    if (window !== undefined) {
      window.location.hash = "#about";
    }
  }

  let image_zoom = '30%';
  let inner_zoom = '70%';

  if (headshot_zoom && headshot_zoom === 'zoom_out') {
    image_zoom = '40%';
    inner_zoom = '60%';
  }

  return (
    <Container>
      <ImageContainer image_zoom={image_zoom}>
        <PBJ_Image
          image={headshot}
          alt={`${first_name} ${surname}`}
          imagewidth={1000}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      </ImageContainer>
      <Inner overlay_colour={colour} text_colour={textColour} inner_zoom={inner_zoom} image_zoom={image_zoom}>
        <h1>{first_name} {surname}</h1>
        <h2><Balancer>{first_sentence}</Balancer></h2>
        <p><Balancer>{remaining_sentences}</Balancer><br />
          <a href="#" onClick={(e) => readMore(e, 'about')}>Read More</a></p>
      </Inner>
    </Container>
  )
}

export default ArtistIntro
