import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  margin-top: 4rem;
  margin-bottom: 4rem;
  h3 {
      color: var(--pink);
    }
    ul {
      list-style: none;
      margin-left: 0;
      padding-left: 0;
    }
    li {
      margin-bottom: 1rem;
      margin-left: 0;
      padding-left: 0;
      padding-right: 1rem;
      font-size: 18px;
      line-height: 28px;
      font-weight: 400;
      a {
        font-family: 'Catamaran',sans-serif;
        text-decoration: none;
        color: var(--darkgrey);
        padding-left: 0;
        margin-left: 0;
      }
    }
`


const Sounds = ({sounds}) => {

  console.log(sounds)

  return (
    <Container>
      {sounds.map((sound, index) => {
        return (
          <div key={index}>
            <h3>{sound.title}</h3>
            <ul>
              {sound.links.map((link, index) => {
                return (
                  <li key={index}>
                    <a href={link.url} target="_blank" rel="noopener noreferrer">{link.title}</a>
                  </li>
                )
              })}
              </ul>
          </div>
        )})}
    </Container>
  )
}

export default Sounds
