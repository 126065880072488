import React from "react";
import styled from "styled-components";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import "lightgallery.js/dist/css/lightgallery.css";
import { LightgalleryItem, LightgalleryProvider } from "react-lightgallery";

const Container = styled.div`
  margin-bottom: 1rem;
  img {
    cursor: zoom-in;
  }
  .lg-outer .lg-thumb-item.active,
  .lg-outer .lg-thumb-item:hover {
    border-color: var(--pink) !important;
  }
`;

const ArtistMasonry = (props) => {
  const { photos } = props;

  photos
    .sort(function (a, b) {
      return parseFloat(a.height) - parseFloat(b.height);
    })
    .reverse();

  let colcount = 3;

  let breakpoints = { 350: 1, 750: 2, 900: 3 };

  if (photos.length === 1) {
    colcount = 1;
    breakpoints = { 350: 1, 750: 1, 900: 1 };
  }

  if (photos.length === 2) {
    colcount = 2;
    breakpoints = { 350: 1, 750: 2, 900: 2 };
  }

  return (
    <Container>
      <LightgalleryProvider>
        <ResponsiveMasonry columnsCountBreakPoints={breakpoints}>
          <Masonry columnsCount={colcount} gutter="16px">
            {photos.map((g, index) => {
              const html = `${g.caption || ""} ${g.attribution || ""}`.trim();

              if (html) {
                return (
                  <LightgalleryItem key={index} src={g.preview} downloadUrl={g.dl} subHtml={`${html}`}>
                    <img src={g.src} />
                  </LightgalleryItem>
                );
              } else {
                return (
                  <LightgalleryItem key={index} src={g.preview} downloadUrl={g.dl}>
                    <img src={g.src} />
                  </LightgalleryItem>
                );
              }
            })}
          </Masonry>
        </ResponsiveMasonry>
      </LightgalleryProvider>
    </Container>
  );
};

export default ArtistMasonry;
