import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Linkify from 'react-linkify';
import { slugify } from '../../lib/utils'
import { blockContentToPlainText } from 'react-portable-text'

const Section = styled.section`
  padding-bottom: 2rem;
  @media (max-width: 880px) {
    padding-bottom: 0;
  }
  table {
    width: 100%;
    border-collapse: collapse;
  }
  tr {
    @media (max-width: 880px) {
      .date-col {
        display: none;
      }
      display: flex;
      flex-direction: column-reverse;
    }
  }
  tr:nth-child(even) {
    background: var(--lightblue);
  }
  h3 {
    color: var(--pink);
    margin-bottom: 1rem;
    padding-left: 1rem;
    @media (max-width: 880px) {
      padding-left: 0.5rem;
    }
  }
  td {
    vertical-align: top;
    font-family: 'Catamaran',sans-serif;
    color: #9D9D9D;
    padding: 2rem 2rem 1.75rem 2rem;
    p a  {
      font-size: 25px;
      font-weight: 300;
    }
    @media (max-width: 880px) {
      padding-left: 0.5rem;
    }
    span {
      font-family: 'Catamaran',sans-serif;
      font-size: 20px;
      color: var(--darkgrey);
    }
  }
`


const Date = styled.p`
  margin-top: 0;
  padding-right: 1rem;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
`

const ArtistNews = ({ id, artist_name, setNumNews }) => {

  // date(formatString: "DD/MM/YYYY")


  const data = useStaticQuery(graphql`
    query {
      allSanityNews(sort: {fields: date, order: DESC}) {
        edges {
          node {
            _id
            title
            date(formatString: "Do MMMM YYYY")
            related_artists {
              surname
              first_name
              _id
            }
            body_blocks {
              _rawChildren
              _type
              list
              style
              children {
                _key
                _type
                text
                marks
              }
            }
          }
        }
      }
    }
  `)

  const all_news = data.allSanityNews.edges;

  const filtered_news = all_news ? all_news.filter(n => (n.node.title.includes(artist_name) || blockContentToPlainText(n.node?.body_blocks || false).includes(artist_name))) : [];

  const related_news = []

  all_news.map(a => {
    if (a.node.related_artists && a.node.related_artists.length > 0) {
      a.node.related_artists.map(b => {
        if (b._id === id) {
          related_news.push(a)
        }
      })
    }
  })

  function unique(array, propertyName) {
    return array.filter((e, i) => array.findIndex(a => a[propertyName] === e[propertyName]) === i);
 }

  const news_ids = []

  const combined_news = [...related_news, ...filtered_news].map(n => {
    if(!news_ids.includes(n.node._id)) {
      news_ids.push(n.node._id);
      return(n);
    }
  }).filter(Boolean);

  setNumNews(filtered_news ? filtered_news.length + related_news.length : 0);

  return (
    <Section>
      <h3>News</h3>
      <table>
        <tbody>
          {combined_news && combined_news.length > 0 && combined_news.map((f, index) => {
            const url = "/news/" + slugify(f.node.title);
            const news_body = blockContentToPlainText(f.node.body_blocks);
            return (
              <tr key={index}>
                <td>
                  <p><a href={url}>{f.node.title}</a></p>
                  <Date>{f.node.date}</Date>
                  <Linkify><p>{news_body.split('.')[0].split('!')[0].split('http')[0]}</p></Linkify>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </Section>
  )
}

export default ArtistNews
