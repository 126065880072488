import React from 'react'
import styled from 'styled-components'
import getVideoId from 'get-video-id'
import ReactPlayer from "react-player"

const VideoContainer = styled.div`
  margin-top: 1rem;
`

const YoutubeEmbed = ({ id }) => (
  <div className="video-responsive">
    <iframe
      width="853"
      height="480"
      src={`https://www.youtube.com/embed/${id}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);

const ArtistVideos = ({ videos }) => {

  return (
    videos.map((v, index) => {

      const id = getVideoId(v.youtube_vimeo).id;

      if (v.youtube_vimeo.includes('youtu')) {

        return (
          <VideoContainer key={index}><YoutubeEmbed id={id} /></VideoContainer>
        )

      } else if (v.youtube_vimeo.includes('vimeo')) {

        return (
          <VideoContainer key={index}>
            <div className='embed-container'>
              <iframe src={`https://player.vimeo.com/video/${id}`} frameBorder='0' allowFullScreen>
              </iframe>
            </div>
          </VideoContainer>
        )

      } else {
        <></>
      }
    }))
}

export default ArtistVideos

