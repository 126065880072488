import React from 'react'
import styled from 'styled-components'
import TableCredits from '../table_credits'

const Section = styled.section`
  padding-bottom: 2rem;
  width: 100%;
  table {
    width: 100%;
    border-collapse: collapse;
  }
  tr:nth-child(even) {
    background: var(--lightblue);
  }
  h3 {
    color: var(--pink);
    margin-bottom: 1rem;
    padding-left: 1rem;
    @media print {
      margin-bottom: 0.5rem;
      font-size: 0.75rem !important;
    }
    @media (max-width: 880px) {
      padding-left: 0.5rem;
    }
  }
  tr {
    @media print {
      break-inside: avoid;
    }
  }
  }
  td {
    vertical-align: top;
    font-family: 'Catamaran',sans-serif;
    color: #9D9D9D;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1rem;
    @media print {
      padding-bottom: 0.5rem;
      padding-top: 0;
      font-size: 0.75rem;
    }
    @media (max-width: 880px) {
      padding-left: 0.5rem;
    }
    &.col1 {
      width: 46%;
    }
    &.col2 {
      width: 38%;
    }
    &.col3 {
      width: 16%;
    }
    @media print {
      &.col1 {
        width: auto;
      }
      &.col2  {
        width: 25%;
      }
      &.col3 {
        width: 16%;
      }
    }
    span {
      font-family: 'Catamaran',sans-serif;
      font-size: 20px;
      color: var(--darkgrey);
      @media print {
        font-size: 0.75rem;
      }
    }
  }
`

const ArtistCredits = (props) => {

  const { credit_order, credits } = props;

  const ordered_credits = credits;

  ordered_credits.sort((a, b) => parseFloat(b.startdate) - parseFloat(a.startdate));

  return (
    <div>
      {credit_order.map((co, index) => {
        if (co && co.title) {
          return (
            <Section key={index}>
              <h3>{co.title}</h3>
              <table>
                <tbody>
                <TableCredits group={co.title} credits={ordered_credits} />
                </tbody>
              </table>
            </Section>
          )
        }
      })}
    </div>
  )
}

export default ArtistCredits
