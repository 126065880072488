import React from 'react'
import styled from 'styled-components'
import Portable from '../portable'

const Container = styled.div`
    ul {
      margin-top: 1rem;
      margin-bottom: 1rem;
      li {
        font-family: Catamaran, sans-serif;
        font-size: 18px;
        line-height: 28px;
        color: var(--text);
      }
    }
    @media(max-width: 880px) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
`

const ArtistAbout = ({ _rawBiographyBlocks, biography_blocks }) => {
  return (
    <Container>
      <Portable blocks={_rawBiographyBlocks} />
    </Container>
  )
}

export default ArtistAbout
