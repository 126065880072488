import React from 'react'

const TableCredits = ({ credits, group }) => {

  // credits.sort((a, b) => parseFloat(a.startdate) - parseFloat(b.startdate)).reverse();

  return (
    credits.map((c, index) => {

      let datum = '';

      if (c.startdate && c.enddate) {

        const start_year = c.startdate.split('-')[0];
        const end_year = c.enddate.split('-')[0];
        if (c.show_end_date) {
          datum = start_year + ' — ' + end_year;
        } else if (start_year === end_year) {
          datum = start_year;
        } else {
          datum = start_year + ' — ' + end_year;
        }

      } else if (c.startdate) {
        datum = c.startdate.split('-')[0];
      }

      const info = c.info ? c.info : ''

      if (group === c.category) {
        return (
          <tr key={index}>
            <td className="col1 no-print"><span>{c.title}</span><br />{c.role}</td>
            <td className="col1 print-only"><span>{c.title}</span> - {c.role}</td>
            <td className="col2">{info}</td>

            {c.hide_date &&
              <td></td>
            }
            {!c.hide_date &&
              <td className="col3" style={{ textAlign: 'right', paddingRight: '1rem' }}>
                {c.startdate ? datum : ''}
              </td>
            }
          </tr>
        )
      }

    })
  )
}

export default TableCredits;
