import React from 'react'
import styled from 'styled-components'
import Apple from '../../images/apple.svg'
import Spotify from '../../images/spotify.svg'
import Acast from '../../images/acast.svg'
import BBC from '../../images/bbc.svg'
import Amazon from '../../images/amazon.svg'
import Pocketcasts from '../../images/pocketcasts.svg'
import Google from '../../images/google.svg'
import DefaultImage from '../../images/news_default.jpg'

const Container = styled.div`
  padding-bottom: 2rem;
  table {
    width: 100%;
    border-collapse: collapse;
  }
  tr:nth-child(even) {
    background: var(--lightblue);
  }
  h3 {
    color: var(--pink);
    margin-bottom: 1rem;
    padding-left: 1rem;
    @media (max-width: 880px) {
      padding-left: 0.5rem;
    }
  }
  td {
    vertical-align: top;
    font-family: 'Catamaran',sans-serif;
    color: #9D9D9D;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1rem;
    padding-right: 1rem;
    &:first-of-type {
      width: 80%;
    }
    &:nth-of-type(2) {
      @media (max-width: 880px) {
        display: none;
      }
    }
    @media (max-width: 880px) {
      padding-left: 0.5rem;
    }
    img.logo {
      height: 24px;
      float: left;
      margin-top: 0.75rem;
      margin-right: 0;
    }
    span {
      font-family: 'Catamaran',sans-serif;
      font-size: 20px;
      color: var(--darkgrey);
    }
  }
  h3 {
    color: var(--pink);
    margin-bottom: 1rem;
    padding-left: 1rem;
    @media (max-width: 880px) {
      padding-left: 0.5rem;
    }
  }
`

const AlbumArt = styled.img`
  aspect-ratio: 1/1;
  object-fit: cover;
  height: 150px;
  width: 150px;
  padding-bottom: 0 !important;
`

const AlbumArtContainer = styled.div`
  width: 150px;
  height: 150px;
`


const AlbumArtContainerMobile = styled.div`
  display: none;
  @media(max-width: 880px) {
    display: block;
    width: 300px;
    height: 300px;
    margin-bottom: 2rem;
    img {
      width: 300px;
      height: 300px;
      object-fit: cover;
    }
  }
`

const Icons = styled.div`
  display: flex;
  column-gap: 0.75rem;
  justify-content: flex-start;
  align-items: center;
  img {
    &:hover {
      filter: brightness(0%);
    }
  }
`

const MainPodcasts = styled.div`
  margin-bottom: 4rem;
  @media(max-width: 880px) {
    margin-bottom: 2rem;
  }
`

const ArtistPodcasts = ({ related_podcasts, podcasts }) => {

  const main_podcasts = podcasts.filter(p => p.guest_appearance !== true)
  const guest_podcasts = podcasts.filter(p => p.guest_appearance === true)

  const all_main_podcasts = [...main_podcasts, ...related_podcasts];

  return (
    <Container>


      {all_main_podcasts && all_main_podcasts.length > 0 &&

        <MainPodcasts>

          <h3>Podcasts</h3>

          <table>
            <tbody>
              {all_main_podcasts.map((p, index) => {

                const albumart = p?.image?.asset?.url ? p.image.asset.url : DefaultImage;

                let description = false;

                if(p.hasOwnProperty('description') && p.description !== null && p.description.length > 0) {
                  description = p.description.replaceAll('&#39;', '').replaceAll('&rsquo;', "'").replaceAll("&nbsp;", " ");
                }

                return (
                  <tr key={index}>
                    <td>
                      <AlbumArtContainerMobile>
                        <AlbumArt src={albumart} />
                      </AlbumArtContainerMobile>

                      <span>{p.title}</span><br />
                      {description &&
                        <>{description}</>
                      }
                      <Icons>
                        {p.podcast_acast &&
                          <a href={p.podcast_acast}><img className="logo" src={Acast} alt="Acast" /></a>
                        }

                        {p.podcast_apple &&
                          <a href={p.podcast_apple}><img className="logo" src={Apple} alt="Apple" /></a>
                        }

                        {p.podcast_spotify &&
                          <a href={p.podcast_spotify}><img className="logo" src={Spotify} alt="Spotify" /></a>
                        }

                        {p.podcast_bbc &&
                          <a href={p.podcast_bbc}><img className="logo" src={BBC} alt="BBC" /></a>
                        }

                        {p.podcast_amazon &&
                          <a href={p.podcast_amazon}><img className="logo" src={Amazon} alt="Amazon" /></a>
                        }

                        {p.podcast_pocketcasts &&
                          <a href={p.podcast_pocketcasts}><img className="logo" src={Pocketcasts} alt="Pocketcasts" /></a>
                        }

                        {p.podcast_google &&
                          <a href={p.podcast_google}><img className="logo" src={Google} alt="Google" /></a>
                        }

                      </Icons>
                    </td>
                    <td><AlbumArtContainer><AlbumArt src={albumart} /></AlbumArtContainer></td>
                  </tr>
                )
              })}
            </tbody>
          </table>

        </MainPodcasts>

      }

      {guest_podcasts && guest_podcasts.length > 0 &&
        <div>
          <h3>Podcast Guest Appearances</h3>

          <table>
            <tbody>
              {guest_podcasts.map((p, index) => {

                let description = false;

                if(p.hasOwnProperty('description') && p.description !== null && p.description.length > 0) {
                  description = p.description.replaceAll('&#39;', '').replaceAll('&rsquo;', "'").replaceAll("&nbsp;", " ");
                }

                return (
                  <tr key={index}>
                    <td>
                      <span>{p.title}</span><br />
                      {description &&
                        <>{description}</>
                      }
                      <Icons>

                        {p.podcast_acast &&
                          <a href={p.podcast_acast}><img className="logo" src={Acast} alt="Acast" /></a>
                        }

                        {p.podcast_apple &&
                          <a href={p.podcast_apple}><img className="logo" src={Apple} alt="Apple" /></a>
                        }

                        {p.podcast_spotify &&
                          <a href={p.podcast_spotify}><img className="logo" src={Spotify} alt="Spotify" /></a>
                        }

                        {p.podcast_bbc &&
                          <a href={p.podcast_bbc}><img className="logo" src={BBC} alt="BBC" /></a>
                        }

                        {p.podcast_amazon &&
                          <a href={p.podcast_amazon}><img className="logo" src={Amazon} alt="Amazon" /></a>
                        }

                        {p.podcast_pocketcasts &&
                          <a href={p.podcast_pocketcasts}><img className="logo" src={Pocketcasts} alt="Pocketcasts" /></a>
                        }

                        {p.podcast_google &&
                          <a href={p.podcast_google}><img className="logo" src={Google} alt="Google" /></a>
                        }

                      </Icons>
                    </td>
                    <td><AlbumArtContainer><AlbumArt src={p.image.asset.url} /></AlbumArtContainer></td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      }

    </Container>
  )
}

export default ArtistPodcasts
